import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
    container: {
        maxWidth: 1258,
        marginLeft: 'auto',
        marginRight: 'auto',
        '&:has(> *:empty)': {
            display: 'none',
        },
    },
    wrapper: {
        paddingLeft: 13,
        paddingRight: 13,
        '@media (min-width: 768px)': {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },
});

export default function ContentBoundary({
    children, className, wrapperProps = {}, ...other
}) {
    const styles = useStyles();
    const { className: wrapperClassName, ...otherWrapperProps } = wrapperProps;

    return (
        <Box className={classNames(styles.container, className)} {...other}>
            <Box className={classNames(styles.wrapper, wrapperClassName)} {...otherWrapperProps}>
                {children}
            </Box>
        </Box>
    );
}
