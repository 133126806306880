import Layout from '@layout';
import { getHost } from '@helper_config';
import { getCookies } from '@helper_cookies';
import { currencyVar } from '@root/core/services/graphql/cache';
import Cookies from 'js-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { getCartId } from '@helper_cartid';

import dynamic from 'next/dynamic';
// import { getShopVaganzaConfig } from '../../service/graphql';

const MobileHeader = dynamic(() => import('./components/MobileHeader'));

const HomeCore = (props) => {
    const {
        Content, pageConfig, storeConfig, ...other
    } = props;

    // let cartId = '';
    // if (typeof window !== 'undefined') {
    //     cartId = getCartId();
    // }

    // const { data } = getShopVaganzaConfig({
    //     variables: {
    //         cartId,
    //     },
    //     skip: !cartId,
    // });

    if (typeof window !== 'undefined') {
        const appCurrency = Cookies.get('app_currency');
        currencyVar({
            currency: storeConfig.base_currency_code,
            locale: storeConfig.locale,
            enableRemoveDecimal: storeConfig?.pwa?.remove_decimal_price_enable,
            appCurrency,
        });
    }

    const schemaOrg = [
        {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: `${getHost()}/`,
            logo: `${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`,
        },
        {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: `${getHost()}/`,
            potentialAction: [{
                '@type': 'SearchAction',
                target: `${getHost()}/catalogsearch/result?q={search_term_string}`,
                'query-input': 'required name=search_term_string',
            }],
        },
    ];

    const config = {
        title: storeConfig.default_title,
        header: false, // available values: "absolute", "relative", false (default)
        bottomNav: 'home',
        pageType: 'home',
        fullWidth: true,
        schemaOrg,
        ...pageConfig,
    };

    const [show, setShow] = React.useState(true);
    const coupon = getCookies('coupon');

    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <Layout
            {...props}
            {...other}
            pageConfig={config}
            CustomHeader={<MobileHeader storeConfig={storeConfig} coupon={coupon} setShow={setShow} show={show} {...other} />}
            stickyHeader={!isDesktop}
        >
            <Content
                storeConfig={storeConfig}
                {...other}
                coupon={coupon}
                setShow={setShow}
                show={show}
                // enableShopvaganza={data?.calculationShopVaganza?.enable}
            />
        </Layout>
    );
};

export default HomeCore;
