/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
// import Skeleton from '@material-ui/lab/Skeleton';
// import ErrorInfo from '@core_modules/home/pages/default/components/ErrorInfo';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_800, GRAY_500, GRAY_100 } from '@src_theme/colors';
import { getCmsBlocks } from '@modules/theme/services/graphql';
import { useTranslation } from '@i18n';
import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles({
    root: {
        padding: '40px 0',
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            borderTop: `1px solid ${GRAY_100}`,
            top: 0,
            left: 0,
            right: 0,
        },
        '& .mega-footer': {
            color: GRAY_500,
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: 1.43,
        },
        '& .mega-footer h2': {
            fontSize: '24px',
            fontWeight: 600,
            margin: 0,
            color: GRAY_800,
            paddingBottom: '16px',
        },
        '& .mega-footer h4': {
            fontSize: '14px',
            fontWeight: 600,
            color: GRAY_800,
            margin: 0,
            paddingBottom: '8px',
        },
        '& .mega-footer p': {
            margin: 0,
            paddingBottom: '24px',
        },
        '& .mega-footer-grid': {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(286px, 1fr))',
            rowGap: '21px',
            columnGap: '24px',
        },
        '& .mega-footer-grid ul': {
            padding: 0,
            margin: 0,
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            rowGap: '8px',
            columnGap: '16px',
        },
        '& .mega-footer-grid ul li a': {
            fontWeight: 300,
            fontSize: '12px',
            color: GRAY_500,
        },
        '& .mega-footer-grid ul li a:hover': {
            textDecoration: 'underline',
        },
    },
});

export default function MegaFooter() {
    const styles = useStyles();
    const { i18n, t } = useTranslation();
    const { data, loading, error } = getCmsBlocks({
        identifiers:
            i18n.language === 'id'
                ? ['pwa_footer_mega_grid_header_id', 'pwa_footer_mega_grid']
                : ['pwa_footer_mega_grid_header_en', 'pwa_footer_mega_grid'],
    });
    const megaFooterRef = useRef();
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries?.length > 0 && entries[0].isIntersecting && !display) {
                setDisplay(true);
            }
        });

        if (megaFooterRef.current) {
            observer.observe(megaFooterRef.current);
        }

        return () => observer.disconnect();
    }, [megaFooterRef]);

    // if (loading) return <Skeleton variant="rect" width="100%" height={150} style={{ marginBottom: 32 }} />;

    // if (error) return <ErrorInfo variant="error" text={t('home:errorFetchData')} />;

    const header = data?.cmsBlocks?.items?.[0]?.content ?? '';
    const grid = data?.cmsBlocks?.items?.[1]?.content ?? '';

    return (
        <div className={styles.root} ref={megaFooterRef}>
            {display && !loading && !error ? (
                <div className="mega-footer">
                    <div dangerouslySetInnerHTML={{ __html: header }} />
                    <div className="hidden-mobile" dangerouslySetInnerHTML={{ __html: grid }} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
