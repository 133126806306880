/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable object-curly-newline */
import Banner from '@modules/commons/Slick/Banner';
import classNames from 'classnames';
import useStyles from '../style';

const BannerView = (props) => {
    const { images, useThumbor } = props;
    const styles = useStyles();
    return (
        <div className={classNames(styles.header, 'home-banner')}>
            {images && images.length && (
                <Banner
                    data={images}
                    // showArrow={desktop}
                    showArrow
                    useThumbor={useThumbor}
                    isHomepageBanner
                />
            )}
            <style jsx>
                {`
                    .home-banner :global(.slick-slide img) {
                        border-radius: 10px;
                    }
                    .home-banner :global(.slick-slide span) {
                        padding-top: 28% !important;
                    }
                    .header-middle__search {
                        display: flex;
                        flex-grow: 1;
                        align-items: center;
                        position: relative;
                        max-width: 500px;
                    }
                    .search-box {
                        width: 100%;
                        border-radius: 99px;
                        border: 2px solid #7696c7;
                        text-align: start;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        padding-left: 5px;
                    }
                    .search-box :global(span) {
                        color: #858997;
                    }
                    .search-icon {
                        position: absolute;
                        width: 20px;
                        right: 20px;
                        top: 25px;
                        border-radius: 99px;
                        background: #fff;
                    }
                    .search-icon img {
                        width: 16px;
                    }
                    @media (max-width: 767px) {
                        .home-banner {
                            display: block;
                        }
                        .home-banner .img-logo {
                            position: relative;
                            text-align: center;
                        }
                        .home-banner :global(.slick-slide a > div) {
                            padding-top: 0;
                        }
                        .home-banner :global(.slick-slide span) {
                            padding-top: 93% !important;
                        }
                        .home-banner :global(.slick-slide a > div img) {
                            position: unset;
                            /* border-radius: 0; */
                        }
                        .home-banner .banner-carousel {
                            padding: 10px;
                        }
                        .search-icon {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        .home-banner :global(img) {
                            height: auto !important;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default BannerView;
